<template>
    <div>
        <card-header title="Edit Response" icon="fa-pencil"/>

        <card-body>
            <card-list>
                <subheader-list-item icon="fa-clipboard-list-check" :title="response.question.question"/>
                <container-list-item padded>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Participant" label-position="inside">
                                <b-select expanded v-model="response.user_id">
                                    <option :value="registration.user.id">{{registration.user.last_name}}, {{registration.user.first_name}} (Primary)</option>
                                    <option :value="registration.second_user.id" v-if="registration.second_user">{{registration.second_user.last_name}}, {{registration.second_user.first_name}} (Secondary)</option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Response" label-position="inside">
                                <b-input v-model="response.response" />
                            </b-field>
                        </div>
                    </div>

                    <ul>
                        <li v-for="a in response.question.answers" @click="response.response = a.answer" class="p-2" style="cursor: pointer !important;">{{a.answer}}</li>
                    </ul>
                </container-list-item>
            </card-list>
        </card-body>

        <card-footer class="pt-3 pb-3">
            <div class="columns">
                <div class="column">
                    <b-button type="is-warning is-outlined" size="is-small" @click="deleteResponse"><i class="far fa-trash-alt"></i><span class="ml-2" v-if="confirmDelete">Delete Response</span></b-button>
                </div>
                <div class="column has-text-right">
                    <b-button type="is-primary" size="is-small" @click="submit"><i class="fas fa-check mr-2"></i>Save Response</b-button>
                </div>
            </div>
        </card-footer>
    </div>

</template>

<script>
    import async from "async";
    import {client as http} from "../../http_client";
    import CardHeader from "../../TIER/components/CardHeader";
    import CardBody from "../../TIER/components/CardBody";
    import CardFooter from "../../TIER/components/CardFooter";
    import RegistrationListItem from "../../components/RegistrationListItem";
    import CardList from "../../TIER/components/CardList";
    import {mapActions} from "vuex";
    import ContainerListItem from "@/TIER/components/ContainerListItem";
    import SubheaderListItem from "@/TIER/components/SubheaderListItem";

    export default {
        components: {SubheaderListItem, ContainerListItem, CardList, RegistrationListItem, CardFooter, CardBody, CardHeader},
        props: ['card', 'props'],
        data: function() {
            return {
                response: {
                    question: {}
                },
                registration: {
                    user: {}
                },
                confirmDelete: false,
                dirty: null
            }
        },
        methods: {
            ...mapActions(['updateTasks']),
            loadResponse: function() {
                this.$emit('loading', true);
                this.dirty = null;

                async.series([
                    // Get response
                    (cb) => {
                        http.get('/api/registrations/' + this.props.registrationId + '/responses/' + this.props.responseId, {force: true}).then(response => {
                            this.response = response.data;
                            cb();
                        });
                    },
                    // Get registration
                    (cb) => {
                        http.get('/api/registrations/' + this.props.registrationId, {force: true}).then(response => {
                            this.registration = response.data;
                            cb();
                        });
                    }
                ], err => {
                    this.$emit('loading', false);
                });
            },
            submit() {
                this.$emit('loading', true);

                http.put('/api/registrations/' + this.props.registrationId + '/responses/' + this.props.responseId, {
                    user_id: this.response.user_id,
                    response: this.response.response
                }, {force: true}).then(response => {
                    this.updateTasks();
                    this.$reloadCard('registrations');
                    this.$reloadCard('registration', {registrationId: this.props.registrationId});
                    this.$reloadCard('responses', {registrationId: this.props.registrationId});
                    this.$reloadCard('question-responses');
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            },
            deleteResponse() {
                if (this.confirmDelete) {
                    this.$emit('loading', true);

                    http.delete('/api/registrations/' + this.props.registrationId + '/responses/' + this.props.responseId).then(response => {
                        this.$reloadCard('registrations');
                        this.$reloadCard('registration', {registrationId: this.props.registrationId});
                        this.$reloadCard('responses', {registrationId: this.props.registrationId});
                        this.$reloadCard('question-responses');
                        this.$saveAlert(false);
                        this.$closeCard(this.card);
                    }).catch(err => {

                    });
                } else {
                    this.confirmDelete = true;
                    setTimeout(() => {
                        this.confirmDelete = false;
                    }, 3000);
                }
            }
        },
        watch: {
            'props.responseId': function() {
                this.loadResponse();
            },
            'event': {
                deep: true,
                handler: function () {
                    this.dirty = (this.dirty !== null);
                    if (!this.$TIER.saveAlert.active && this.dirty)
                        this.$saveAlert(true, 'Are you done editing this response? Unsaved changes will be lost.');
                }
            }
        },
        mounted() {
            this.loadResponse();
        }
    };
</script>

<style scoped>

</style>
